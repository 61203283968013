import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { Currency } from "../../components/task/types";
import {
  createExpense,
  Expense,
  updateExpense,
} from "../../redux/reducerSlices/expense";
import { getInvites } from "../../redux/reducerSlices/invite";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormattedMessage } from "react-intl";
import { getIntl } from "../../GlobalIntlProvider";
import axios from "axios";
import { formatTaskTitle } from "../../components/task/functions";

type Props = {
  expense?: Expense;
  submitEffect?: () => void;
};

export const ExpenseForm: FC<Props> = ({ expense, submitEffect }) => {
  const schema = z.object({
    date: z.string().min(
      1,
      getIntl().formatMessage({
        id: "validation.Required",
        defaultMessage: "Required",
      })
    ),
    memo: z.string().min(
      1,
      getIntl().formatMessage({
        id: "validation.Required",
        defaultMessage: "Required",
      })
    ),
    talent: z.string().optional(),
    hours: z.number().positive(
      getIntl().formatMessage({
        id: "validation.MustBePositive",
        defaultMessage: "Must be positive",
      })
    ),
    price: z.number().positive(
      getIntl().formatMessage({
        id: "validation.MustBePositive",
        defaultMessage: "Must be positive",
      })
    ),
    currency: z.nativeEnum(Currency).default(Currency.BGN),
    publishedAt: z.string().nullable(),
    // task: z.number().nullable(),
    user: z.number().nullable(),
  });
  type ExpensePayload = z.infer<typeof schema>;

  const f = useForm<ExpensePayload>({
    resolver: zodResolver(schema),
    defaultValues: {
      date: expense?.date ?? new Date().toISOString().slice(0, 10),
      memo: expense?.memo ?? "",
      talent: expense?.talent ?? "",
      hours: expense?.hours ?? 0,
      price: expense?.price ?? 0,
      currency: expense?.currency ?? Currency.BGN,
      user: expense?.user?.id ?? null,
      publishedAt: expense?.publishedAt ?? null,
    },
  });

  const { date, memo, hours, price } = f.formState.errors;
  const { inviteState } = useSelector((s: RootStateOrAny) => s);

  const dispatch = useDispatch();
  const isEdit = !!expense;

  const usersOptions = React.useMemo(() => {
    const { all } = inviteState;
    const users = all.map((el: any) => el.invitee).filter(Boolean);

    return users.map((u: any) => ({ value: u.id, label: u.email }));
  }, [inviteState]);

  const [tasksOptions, setTasksOptions] = React.useState<any[]>([]);
  const [loadingOptions, setLoadingOptions] = React.useState(true);
  const [extTask, setExtTask] = React.useState<any>(null);

  const findTasks = async (searchTerm = "") => {
    const tasksRes = await axios.get(
      `/find-tasks-by-title?searchTerm=${searchTerm}`
    );

    const tasks = tasksRes.data;

    setTasksOptions(tasks);
    setLoadingOptions(false);
  };

  React.useEffect(() => {
    if (expense?.task) {
      setExtTask(expense?.task);
    }
  }, [expense?.task]);

  React.useEffect(() => {
    dispatch(getInvites());
  }, [dispatch]);

  const onSubmit = (data: ExpensePayload) => {
    // data.task = extTask?.id;

    const payload = {
      ...data,
      task: extTask?.id,
    };

    if (isEdit) {
      dispatch(updateExpense(expense.id, payload));
    } else {
      dispatch(createExpense(payload));
    }
    submitEffect?.();
  };

  return (
    <form onSubmit={f.handleSubmit(onSubmit)}>
      <DialogContent
        sx={{
          display: "grid",
          gap: 1,
          width: 500,
          padding: "0px 24px",
        }}
      >
        <FormControl error={!!date}>
          <TextField {...f.register("date")} type="date" />
          {Boolean(date?.message) && (
            <FormHelperText>{date?.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl error={!!memo}>
          <TextField
            multiline
            label={getIntl().formatMessage({
              id: "general.Memo",
              defaultMessage: "Memo",
            })}
            {...f.register("memo")}
          />
          {Boolean(memo?.message) && (
            <FormHelperText>{memo?.message}</FormHelperText>
          )}
        </FormControl>
        <TextField
          label={getIntl().formatMessage({
            id: "general.Talent",
            defaultMessage: "Talent",
          })}
          {...f.register("talent")}
        />
        <FormControl error={!!hours}>
          <TextField
            type="number"
            label={getIntl().formatMessage({
              id: "general.Hours",
              defaultMessage: "Hours",
            })}
            value={f.watch("hours")}
            onChange={(e) =>
              f.setValue("hours", +e.target.value, { shouldValidate: true })
            }
          />
          {Boolean(hours?.message) && (
            <FormHelperText>{hours?.message}</FormHelperText>
          )}
        </FormControl>
        <Box display="flex" gap={1}>
          <FormControl sx={{ width: "100%" }} error={!!price}>
            <TextField
              type="number"
              sx={{ flexGrow: 1 }}
              label={getIntl().formatMessage({
                id: "general.Price",
                defaultMessage: "Price",
              })}
              value={f.watch("price")}
              onChange={(e) =>
                f.setValue("price", +e.target.value, { shouldValidate: true })
              }
            />
            {Boolean(price?.message) && (
              <FormHelperText>{price?.message}</FormHelperText>
            )}
          </FormControl>
          <Select
            sx={{ maxHeight: 56 }}
            value={f.watch("currency")}
            {...f.register("currency")}
          >
            {Object.values(Currency).map((c) => (
              <MenuItem value={c} key={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex" flexDirection="column">
          <Autocomplete
            loading={loadingOptions}
            options={tasksOptions}
            getOptionLabel={(option) => formatTaskTitle(option)}
            value={extTask}
            isOptionEqualToValue={(option, value) => option?.value === value}
            onChange={(_, newValue) => {
              setExtTask(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Task" />}
            onInputChange={(e, newInputValue) => {
              findTasks(newInputValue);
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Autocomplete
            options={usersOptions}
            getOptionLabel={(option) => option?.label || ""}
            value={usersOptions.find((t) => t.value === f.watch("user")) || ""}
            onChange={(_, newValue) => {
              f.setValue("user", newValue?.value, { shouldValidate: true });
            }}
            renderInput={(params) => <TextField {...params} label="User" />}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "20px 24px" }}>
        <Button type="submit" color="primary">
          <FormattedMessage id="general.Confirm" defaultMessage="Confirm" />
        </Button>
      </DialogActions>
    </form>
  );
};
