import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    color: {
      primary: string;
      brand: string;
    };
  }
  interface ThemeOptions {
    color?: {
      primary?: string;
      brand?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#05325e",
    },
  },
});

export default theme;
