import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ConfirmDialog from "../ConfirmDialog";
import {
  Quote,
  QuoteStatus,
  Currency,
  Task,
  Client,
  QuoteItem,
  QuoteItemStatus,
} from "../task/types";
import {
  createQuote,
  deleteQuote,
  deleteQuoteItem,
  updateQuote,
} from "../../redux/reducerSlices/quote";
import { AddCircle, Delete } from "@mui/icons-material";
import QuoteItemRow from "./QuoteItemRow";
import CreateTask from "../task/CreateTask";
import { refreshTasks } from "../../redux/reducerSlices/task";
import QuoteItemModal from "./itemModal";
import moment from "moment";
import {
  addTaskToCompany,
  getCompanies,
} from "../../redux/reducerSlices/company";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: `0px 0px 3px`,
  margin: `0 0 2px 0`,
  borderRadius: 5,
  background: "white",

  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightgrey" : "white",
  padding: grid,
});

const emptyQuote = {
  number: 0,
  date: new Date(),
  delivery_time: 14,
  expiration_date: new Date(new Date().setDate(new Date().getDate() + 14)),
  currency: Currency.BGN,
  status: QuoteStatus.draft,
  company: {
    id: 0,
  },
  quote_items: [],
  base_rate: 0,
  partial_invoicing: 50,
} as Quote;

interface CompaniesMap {
  [key: string]: Client;
}

const QuoteModal: React.FC<{
  extQuote?: Quote | null;
  task?: Task | null;
  setOpenModal: any;
  openModal: boolean;
  company?: Client | null;
  onSubmitCB?: () => void;
}> = ({ extQuote, task, setOpenModal, openModal, company, onSubmitCB }) => {
  const hasTask = Boolean(task);
  const isEditingQuote = Boolean(extQuote);
  const {
    project: { projectOptions },
    user,
    companyState,
  } = useSelector((state: RootStateOrAny) => state);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { companies } = companyState;
  const companiesMap: CompaniesMap = {};
  const [shouldFetchClients, setShouldFetchClients] = useState<boolean>(true);

  useEffect(() => {
    if (shouldFetchClients && companies.length === 0) {
      dispatch(getCompanies(1, 100));
      setShouldFetchClients(true);
    }
  }, [dispatch, companies.length, shouldFetchClients]);

  companies.forEach((company: Client) => {
    companiesMap[company.id] = company;
  });

  const [quote, setQuote] = useState<Quote>(emptyQuote);
  const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openConfirmModalItem, setOpenConfirmModalItem] =
    useState<boolean>(false);
  const [companyErr, setCompanyErr] = useState<boolean>(false);
  const [qiToDelete, setQiToDelete] = useState<any>({});
  const [qis, setQis] = useState<QuoteItem[]>([]);
  const [lastQuoteNum, setLastQuoteNum] = useState<number>(0);
  const [modalTask, setModalTask] = useState<Task>(null);
  const [openTaskModal, setOpenTaskModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newQIid, setNewQIid] = useState<number>(0);
  const [openQiModal, setOpenQiModal] = useState<boolean>(false);
  const [modalQi, setModalQi] = useState<QuoteItem>(null);
  const [taskModalOpenedFromQi, setTaskModalOpenedFromQi] =
    useState<QuoteItem>(null);
  const [loadingQis, setLoadingQis] = useState<boolean>(false);

  useEffect(() => {
    if (openModal) {
      const getLastQuoteNum = async () => {
        const response = await axios.get(
          "/quotes?sort[0]=number:desc&pagination[limit]"
        );
        const lastQuoteNum = parseInt(
          response.data?.data[0]?.attributes?.number || 0
        );

        setLastQuoteNum(lastQuoteNum + 1);
        setQuote((quote) => ({
          ...quote,
          number: quote.number || lastQuoteNum + 1,
        }));
      };

      getLastQuoteNum();
    }
  }, [openModal]);

  useEffect(() => {
    if (extQuote) {
      setQuote({
        ...extQuote,
        date: new Date(extQuote.date),
        expiration_date: new Date(extQuote.expiration_date),
      });

      setQis([...(extQuote.quote_items || [])]);
    }
  }, [extQuote, setQuote]);

  useEffect(() => {
    if (company) {
      setQuote((quote) => ({
        ...quote,
        company,
        currency: company.currency,
        base_rate: company.base_rate,
      }));
    }
  }, [company, task]);

  const fetchTranslate = React.useCallback(async () => {
    if (quote.company.language === "en" || !quote.company.language) {
      setQis((qis) => {
        const newQis = qis.map((qi) => {
          if (qi.id === -1000) {
            return {
              ...qi,
              title: task.title,
              description: task.description,
            };
          }

          return {
            ...qi,
          };
        });

        return newQis;
      });

      return null;
    }

    setLoadingQis(true);

    const titleRes = await axios.post("/cs/translate-text", {
      text: task.title,
      language: quote.company.language,
    });

    const descRes = await axios.post("/cs/translate-text", {
      text: task.description,
      language: quote.company.language,
    });

    setQis((qis) => {
      const newQis = qis.map((qi) => {
        if (qi.id === -1000) {
          return {
            ...qi,
            title: titleRes.data.text,
            description: descRes.data.text,
          };
        }

        return {
          ...qi,
        };
      });

      return newQis;
    });

    setLoadingQis(false);
  }, [quote.company.language, task, setQis, setLoadingQis]);

  useEffect(() => {
    if (!isEditingQuote && hasTask && quote.company?.id) {
      fetchTranslate();
    }
  }, [quote.company, isEditingQuote, hasTask, fetchTranslate]);

  useEffect(() => {
    if (hasTask) {
      let price = 0;

      if (task?.estimates?.length && company?.base_rate) {
        const estimate = task.estimates?.reduce((a, b) => {
          return a.hours > b.hours ? a : b;
        });

        price = estimate.hours * company?.base_rate;
      }

      const newQi: QuoteItem = {
        id: -1000,
        price,
        status: QuoteItemStatus.pending,
        task: task,
        title: task.title,
        description: task.description,
        order: 0,
      };

      setQis((qis) => [...qis, newQi]);
    }
  }, [hasTask, task, company]);

  const closeModal = () => {
    setShowFormErrors(false);
    setOpenModal(false);

    setTimeout(() => {
      setQuote(emptyQuote);
      setQis([]);
    }, 200);
  };

  const handleDelete = (quote: Quote) => {
    dispatch(deleteQuote(quote.id));
    setOpenConfirmModal(false);
    setOpenModal(false);
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setQuote({
        ...quote,
        [event.target.name]: event.target.value,
      });
    } else {
      setQuote({
        ...quote,
        [name]: event.toString(),
      });
    }
  };

  const validateQuote = () => {
    if (!quote.number && !lastQuoteNum) {
      return false;
    } else if (!quote.company?.id) {
      setCompanyErr(true);

      return false;
    }

    return true;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateQuote()) {
      setShowFormErrors(true);

      return null;
    }

    setLoading(true);

    if (isEditingQuote) {
      await dispatch(
        updateQuote({
          data: {
            ...quote,
          },
        })
      );
    } else {
      const newQuote = {
        ...quote,
        number: quote.number || lastQuoteNum,
        quote_items: qis,
      };

      delete newQuote.id;

      await dispatch(
        createQuote({
          quote: newQuote,
        })
      );

      if (hasTask) dispatch(refreshTasks());
    }

    if (onSubmitCB) onSubmitCB();

    setLoading(false);
    closeModal();
  };

  const getModalTitle = () => {
    return isEditingQuote ? (
      <FormattedMessage
        id="quote-modal.Edit-Quote"
        defaultMessage="Edit Quote"
      />
    ) : (
      <FormattedMessage id="quote-modal.New-Quote" defaultMessage="New Quote" />
    );
  };

  const openConfirmItemModal = (qi: any) => {
    setOpenConfirmModalItem(true);
    setQiToDelete(qi);
  };

  const handleDeleteQuoteItem = (delQi: QuoteItem) => {
    const uptQis: QuoteItem[] = [...qis].filter((qi) => qi.id !== delQi.id);

    if (delQi.id > 0) {
      dispatch(deleteQuoteItem(delQi.id));
    }

    setQis(uptQis);
    setQuote({
      ...quote,
      quote_items: uptQis,
    });
    setOpenConfirmModalItem(false);
  };

  const updateQi = (
    uptQi: QuoteItem,
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<QuoteItemStatus>
      | { target: { value: Task; name: string } }
  ) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === uptQi.id) {
        if (event.target.name === "task") {
          const task = event.target.value as Task;

          qi.task = task;
          qi.title = task.title;
          qi.description = task.description;
        } else {
          qi[event.target.name] = event.target.value;
        }
      }

      return qi;
    });

    setQis(uptQis);
  };

  const updateQiFromModal = (uptQi: QuoteItem) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === uptQi.id) {
        qi.title = uptQi.title;
        qi.description = uptQi.description;
        qi.task = {
          ...uptQi.task,
        };
        qi.subscription_item = uptQi.subscription_item;
      }

      return qi;
    });

    setQis(uptQis);
    setModalQi(null);
  };

  const updateQiStatus = (uptQi: QuoteItem, newStatus: QuoteItemStatus) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === uptQi.id) {
        if (
          quote.delivery_time &&
          qi.task?.id &&
          newStatus === QuoteItemStatus.accepted &&
          qi.status !== QuoteItemStatus.accepted
        ) {
          qi.task.due_date = moment()
            .add(quote.delivery_time, "days")
            .format("YYYY-MM-DD");
          qi.task.hasChanges = true;
        }

        qi.status = newStatus;
      }

      return qi;
    });

    setQis(uptQis);
  };

  const handleOpenTaskModal = (task: Task) => {
    setModalTask(task);
    setOpenTaskModal(true);
  };

  const handleUpdateTaskCallback = (task: any) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === taskModalOpenedFromQi.id) {
        qi.task = task;
      }

      return qi;
    });

    setQis(uptQis);
  };

  const handleDeleteTaskCallback = (task: any) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === taskModalOpenedFromQi.id) {
        qi.task = null;
      }

      return qi;
    });

    setQis(uptQis);
  };

  const handleCreateTaskCallback = (task: any) => {
    if (taskModalOpenedFromQi) {
      const uptQis = [...qis].map((qi) => {
        if (qi.id === taskModalOpenedFromQi.id) {
          qi.task = task;
        }

        return qi;
      });

      dispatch(
        addTaskToCompany({
          companyId: quote.company.id,
          task: task,
        })
      );

      setQis(uptQis);
      setTaskModalOpenedFromQi(null);
      setModalQi({
        ...taskModalOpenedFromQi,
        task: task,
      });
    }
  };

  const getTotalSum = () => {
    const total =
      qis?.reduce((n, { price: p }) => n + parseFloat(`${p}`), 0) || 0;

    if (total % 1 !== 0) {
      return total.toFixed(2);
    }

    return total;
  };

  const getTotalHours = () => {
    const allEstimateHours = qis?.map((qi) => {
      if (qi.task?.estimates?.length) {
        return qi.task?.estimates[0].hours;
      }

      return 0;
    });

    let totalHours = 0;

    if (allEstimateHours.length) {
      totalHours = allEstimateHours.reduce((a, b) => a + b);

      if (!totalHours) {
        return "";
      }

      if (totalHours % 1 !== 0) {
        totalHours = parseFloat(totalHours.toFixed(1));
      }
    }

    const h = intl.formatMessage({
      id: "general.hours-shortened",
      defaultMessage: "h",
    });

    return `(${totalHours}${h})`;
  };

  const renderStatus = (status) => {
    switch (status) {
      case "draft":
        return (
          <FormattedMessage id="general-status.draft" defaultMessage="draft" />
        );
      case "sent":
        return (
          <FormattedMessage id="general-status.sent" defaultMessage="sent" />
        );
      case "closed":
        return (
          <FormattedMessage
            id="general-status.closed"
            defaultMessage="closed"
          />
        );
      default:
        console.error("unexpected status: ", status);
        return (
          <FormattedMessage
            id="general-status.unexpected-status"
            defaultMessage="unexpected status"
          />
        );
    }
  };

  const onDragEnd = (event: any) => {
    if (!event.destination) {
      return null;
    }

    const items = reorder(
      qis,
      event.source.index,
      event.destination.index
    ) as any;

    setQis(
      items.map((qi, index) => {
        qi.order = index + 1;

        return qi;
      })
    );
  };

  const renderQis = () => {
    if (loadingQis) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {Boolean(isEditingQuote || qis.length) &&
                  qis?.map((qi, index) => {
                    return (
                      <Draggable
                        key={qi.id}
                        draggableId={String(qi.id)}
                        index={index}
                      >
                        {(provided: any, snapshot: any) => (
                          <QuoteItemRow
                            forwardRef={provided.innerRef}
                            {...provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            isDragging={snapshot.isDragging}
                            key={qi.id}
                            company={quote.company}
                            qi={qi}
                            openConfirmItemModal={openConfirmItemModal}
                            openTaskModal={(task) => {
                              if (task?.seen_by && !task.seen_by.length) {
                                setQis(
                                  [...qis].map((i) => {
                                    if (i.id === qi.id)
                                      i.task.seen_by = [{ id: user.id }];

                                    return i;
                                  })
                                );
                              }

                              handleOpenTaskModal(task);
                            }}
                            openQiModal={() => {
                              setModalQi(qi);
                              setOpenQiModal(true);
                            }}
                            updateItem={updateQi}
                            updateQiStatus={updateQiStatus}
                            deleteDisabled={false}
                            updateQiTask={(task) => {
                              setQis(
                                [...qis].map((i) => {
                                  if (i.id === qi.id) {
                                    i.task = task;
                                    i.title = task?.title;
                                    i.description = task?.description;
                                  }
                                  return i;
                                })
                              );
                            }}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <div style={{ width: 1000 }}>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: "100%",
              minWidth: 600,
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item sm={3}>
                  <TextField
                    value={quote.number || lastQuoteNum}
                    id="number"
                    label={
                      <FormattedMessage
                        id="general.Number"
                        defaultMessage="Number"
                      />
                    }
                    type="number"
                    name="number"
                    fullWidth
                    required
                    variant="outlined"
                    error={
                      showFormErrors && quote.number === 0 && lastQuoteNum === 0
                    }
                    helperText={
                      showFormErrors && quote.number === 0
                        ? intl.formatMessage({
                            id: "general.Quote-number-is-required",
                            defaultMessage: "Quote number is required",
                          })
                        : ""
                    }
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="company-label">
                      <FormattedMessage
                        id="general.Client"
                        defaultMessage="Client"
                      />
                    </InputLabel>
                    <Select
                      labelId="company-label"
                      label={
                        <FormattedMessage
                          id="general.Client"
                          defaultMessage="Client"
                        />
                      }
                      name="company"
                      error={companyErr}
                      onChange={(event) => {
                        const company = companiesMap[event.target.value];

                        if (company) {
                          setCompanyErr(false);
                          setQuote({
                            ...quote,
                            company,
                            currency: company.currency,
                            base_rate: company.base_rate,
                            partial_invoicing: company.partial_invoicing || 50,
                          });

                          setQis(
                            [...qis].map((qi) => {
                              if (qi.task?.estimates?.length) {
                                const estimate = qi.task.estimates?.reduce(
                                  (a, b) => {
                                    return a.hours > b.hours ? a : b;
                                  }
                                );

                                qi.price = estimate.hours * company.base_rate;
                              }

                              return qi;
                            })
                          );
                        }
                      }}
                      value={quote.company.id}
                    >
                      <MenuItem value={0}>
                        <FormattedMessage
                          id="general.Select-client"
                          defaultMessage="Select client"
                        />
                      </MenuItem>
                      {companies.map((company) => {
                        return (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {companyErr && (
                      <FormHelperText
                        sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                      >
                        <FormattedMessage
                          id="general.Client-is-required"
                          defaultMessage="Client is required"
                        />
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="currency-label">
                      <FormattedMessage
                        id="general.Currency"
                        defaultMessage="Currency"
                      />
                    </InputLabel>
                    <Select
                      labelId="currency-label"
                      label={
                        <FormattedMessage
                          id="general.Currency"
                          defaultMessage="Currency"
                        />
                      }
                      name="currency"
                      onChange={(event) => handleOnChangeFormField(event)}
                      value={quote.currency.toString()}
                    >
                      {Object.keys(Currency)
                        .filter((q) => isNaN(Number(q)))
                        .map((currency, i) => {
                          return (
                            <MenuItem key={currency + i} value={currency}>
                              {currency}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    value={quote.delivery_time || 0}
                    id="delivery_time"
                    label={
                      <FormattedMessage
                        id="general.Delivery-time"
                        defaultMessage="Delivery time"
                      />
                    }
                    type="number"
                    name="delivery_time"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                      const newVal = Number(event.target.value);
                      if (newVal >= 0) {
                        setQuote({
                          ...quote,
                          delivery_time: newVal,
                        });
                      } else {
                        setQuote({
                          ...quote,
                          delivery_time: 0,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="date"
                      label={
                        <FormattedMessage
                          id="general.Date"
                          defaultMessage="Date"
                        />
                      }
                      type="date"
                      fullWidth
                      value={quote.date.toISOString().slice(0, 10)}
                      onChange={(event) => {
                        if (event.target.value) {
                          setQuote({
                            ...quote,
                            date: new Date(event.target.value),
                            expiration_date: new Date(
                              new Date(event.target.value).setDate(
                                new Date(event.target.value).getDate() +
                                  quote.delivery_time
                              )
                            ),
                          });
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="date"
                      label={
                        <FormattedMessage
                          id="general.Expiration-date"
                          defaultMessage="Expiration date"
                        />
                      }
                      type="date"
                      fullWidth
                      value={quote.expiration_date.toISOString().slice(0, 10)}
                      InputProps={{
                        inputProps: {
                          min: quote.date.toISOString().slice(0, 10),
                        },
                      }}
                      onChange={(event: any) => {
                        const date = new Date(event.target.value);

                        if (event.target.value) {
                          setQuote({
                            ...quote,
                            expiration_date: date,
                            delivery_time: Math.ceil(
                              (date.getTime() - quote.date.getTime()) /
                                (1000 * 3600 * 24)
                            ),
                          });
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="status-label">
                      <FormattedMessage
                        id="general.Status"
                        defaultMessage="Status"
                      />
                    </InputLabel>
                    <Select
                      labelId="status-label"
                      label={
                        <FormattedMessage
                          id="general.Status"
                          defaultMessage="Status"
                        />
                      }
                      name="status"
                      onChange={(event) => handleOnChangeFormField(event)}
                      value={quote.status.toString()}
                    >
                      {Object.keys(QuoteStatus)
                        .filter((q) => isNaN(Number(q)))
                        .map((status, i) => {
                          return (
                            <MenuItem key={status + i} value={status}>
                              {renderStatus(status)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    value={quote.base_rate || 0}
                    id="base_rate"
                    label={
                      <FormattedMessage
                        id="general.Base-rate"
                        defaultMessage="Base rate"
                      />
                    }
                    type="number"
                    name="base_rate"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                      if (Number(event.target.value) >= 0) {
                        handleOnChangeFormField(event);

                        setQis(
                          [...qis].map((qi) => {
                            if (qi.task?.estimates?.length) {
                              const estimate = qi.task.estimates?.reduce(
                                (a, b) => {
                                  return a.hours > b.hours ? a : b;
                                }
                              );

                              qi.price =
                                estimate.hours * Number(event.target.value);
                            }

                            return qi;
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    value={quote.partial_invoicing || 50}
                    id="partial_invoicing"
                    label={
                      <FormattedMessage
                        id="general.%-invoiced-on-approval"
                        defaultMessage="% invoiced on approval"
                      />
                    }
                    type="number"
                    name="partial_invoicing"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                      const val = Number(event.target.value);
                      if (val >= 0 && val <= 100) {
                        handleOnChangeFormField(event);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Typography variant="h5">
                  <FormattedMessage
                    id="quotes-page.Quote-items"
                    defaultMessage="Quote items"
                  />
                </Typography>
                <IconButton
                  size="large"
                  title={intl.formatMessage({
                    id: "quote-modal.Add-quote-item",
                    defaultMessage: "Add quote item",
                  })}
                  onClick={() => {
                    const newQi: QuoteItem = {
                      id: newQIid - 1,
                      title: intl.formatMessage({
                        id: "quote-modal.New-quote-item",
                        defaultMessage: "New quote item",
                      }),
                      status: QuoteItemStatus.pending,
                      price: 0,
                      description: "",
                      order: 0,
                    };

                    setNewQIid(newQIid - 1);

                    const newQis = [newQi, ...qis].map((qi, i) => {
                      return {
                        ...qi,
                        order: i,
                      };
                    });

                    setQis(newQis);

                    setQuote({
                      ...quote,
                      quote_items: newQis,
                    });
                  }}
                >
                  <AddCircle />
                </IconButton>
              </div>
              <div style={{ paddingLeft: 5 }}>
                {hasTask && !quote && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{task.title}</span>
                    <IconButton
                      aria-label="delete"
                      disabled
                      style={{
                        padding: 6,
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )}
                {renderQis()}
              </div>
              <Grid container>
                <Grid item sm={12} textAlign="right" mt={2}>
                  <span>{quote.currency} </span>
                  <span>{getTotalSum()} </span>
                  <span>{getTotalHours()}</span>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEditingQuote && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    <FormattedMessage
                      id="quotes-page.Delete-Quote"
                      defaultMessage="Delete Quote"
                    />
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  <FormattedMessage
                    id="general.Cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  {isEditingQuote ? (
                    <FormattedMessage
                      id="general.Update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="general.Create"
                      defaultMessage="Create"
                    />
                  )}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditingQuote) {
            handleDelete(quote);
          }
        }}
        text={intl.formatMessage({
          id: "quote-modal.Do-you-really-want-to-delete-quote",
          defaultMessage: "Do you really want to delete the quote?",
        })}
      />
      <ConfirmDialog
        openModal={openConfirmModalItem}
        setOpenModal={setOpenConfirmModalItem}
        confirmHandler={() => {
          handleDeleteQuoteItem(qiToDelete);
        }}
        text={intl.formatMessage({
          id: "quote-modal.Do-you-really-want-to-delete-quote-item",
          defaultMessage: "Do you really want to delete the quote item?",
        })}
      />
      <CreateTask
        openModal={openTaskModal}
        setOpenModal={setOpenTaskModal}
        taskFromQi={modalTask}
        task={modalTask}
        projects={projectOptions}
        updateCallback={handleUpdateTaskCallback}
        deleteCallback={handleDeleteTaskCallback}
        createCallback={handleCreateTaskCallback}
      />
      <QuoteItemModal
        openModal={openQiModal}
        quote={quote}
        setOpenModal={() => {
          setOpenQiModal(false);
          setModalQi(null);
        }}
        qi={modalQi}
        updateQi={updateQiFromModal}
        company={quote?.company}
        setModalTask={(qi) => {
          setTaskModalOpenedFromQi(qi);

          let task = {
            title: qi.title,
            description: qi.description,
            company: quote.company,
            status: "Gathering Data",
          } as Task;

          if (qi.id > 0) {
            task.quote_items = [qi.id];
          }

          if (qi.task?.id) {
            task = {
              title: "",
              description: "",
              status: "Gathering Data",
              ...qi.task,
              company: quote.company,
            } as Task;
          }

          setModalTask(task);
          setOpenTaskModal(true);
        }}
      />
    </div>
  );
};

export default QuoteModal;
