import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage } from "react-intl";
import { Box, IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

const Row: React.FC<{
  quote: {
    number: number;
    status: String;
    id?: String;
    handleOpenEdinModal: any;
    trello_url: string;
    project: any | null;
    company: any;
    date: string;
  };
}> = ({ quote }, props) => {
  const { number, status, handleOpenEdinModal, company, date } = quote;
  const handleEditQuote = () => {
    handleOpenEdinModal(quote);
  };

  const renderStatus = () => {
    switch (status) {
      case "draft":
        return (
          <FormattedMessage id="general-status.draft" defaultMessage="draft" />
        );
      case "sent":
        return (
          <FormattedMessage id="general-status.sent" defaultMessage="sent" />
        );
      case "closed":
        return (
          <FormattedMessage
            id="general-status.closed"
            defaultMessage="closed"
          />
        );
      default:
        console.error("unexpected status: ", status);
        return (
          <FormattedMessage
            id="general-status.unexpected-status"
            defaultMessage="unexpected status"
          />
        );
    }
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditQuote();
      }}
    >
      <TableCell component="th" scope="row">
        #{number}
      </TableCell>
      <TableCell component="th" scope="row">
        {company?.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {renderStatus()}
      </TableCell>
      <TableCell component="th" scope="row">
        {date}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              e.nativeEvent.stopPropagation();
              window.open(`/print-quote/${quote.id}`, "_blank");
            }}
          >
            <PrintIcon color="primary" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default Row;
