import React, { FormEvent, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const fornetextLogoDark = require("../../assets/img/fornetex-dark.png");

const Login: React.FC<{ role: string }> = ({ role }) => {
  const user = useSelector((state: any) => state.auth);
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loginErr, setLoginErr] = useState<string>("");
  const [emailValidationErr, setEmailValidationErr] = useState<string>("");
  const [passwordErr, setPasswordErr] = useState<string>("");

  useEffect(() => {
    if (user) {
      history.push("/dashboard");
    }
  }, [user, history]);

  const loginRequest = async (data: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/auth/local`,
        data
      );

      const { jwt } = response.data;
      const me = await axios.get(`${process.env.REACT_APP_API}/users/me`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      const meData = me.data;

      dispatch({
        type: "LOGIN_USER",
        payload: {
          ...meData,
          jwt,
        },
      });
    } catch (e: any) {
      const errMsg = e.response?.data?.error?.message;

      if (errMsg === "Invalid identifier or password") {
        return setLoginErr(
          intl.formatMessage({
            id: "general.InvalidCredentials",
            defaultMessage: "Invalid credentials",
          })
        );
      }

      console.error(e);

      return setLoginErr(
        intl.formatMessage(
          {
            id: "general.SomethingWentWrong",
            defaultMessage: "Something went wrong: {error}",
          },
          { error: errMsg }
        )
      );
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email");
    const password = data.get("password");

    if (!email) {
      return setEmailValidationErr(
        intl.formatMessage({
          id: "general.EmailIsRequired",
          defaultMessage: "Email is required",
        })
      );
    }

    if (!password) {
      return setPasswordErr(
        intl.formatMessage({
          id: "general.PasswordIsRequired",
          defaultMessage: "Password is required",
        })
      );
    }

    loginRequest({
      identifier: email,
      password,
    });
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "30px",
          }}
        >
          <img
            src={fornetextLogoDark}
            alt="Fornetext"
            style={{
              width: "350px",
              maxWidth: "100%",
              marginTop: "50px",
              marginBottom: "40px",
            }}
          />
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={
                <FormattedMessage id="general.Email" defaultMessage="Email" />
              }
              name="email"
              autoComplete="email"
              autoFocus
              error={Boolean(emailValidationErr)}
              helperText={emailValidationErr}
              onChange={() => setEmailValidationErr("")}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={
                <FormattedMessage
                  id="general.Password"
                  defaultMessage="Password"
                />
              }
              type="password"
              id="password"
              autoComplete="current-password"
              error={Boolean(passwordErr)}
              helperText={passwordErr}
              onChange={() => setPasswordErr("")}
            />
            {Boolean(loginErr) && (
              <Typography style={{ color: "red" }}>{loginErr}</Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
              }}
            >
              <FormattedMessage id="general.Login" defaultMessage="Login" />
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
