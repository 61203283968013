import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, CircularProgress, FormHelperText, Grid } from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { CloudUpload } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";

import {
  createPurchaseOrder,
  deletePurchaseOrder,
  updatePurchaseOrder,
  updatePOFiles,
} from "../../redux/reducerSlices/purchase-order";
import Attachment from "../Attachment";
import ConfirmDialog from "../ConfirmDialog";
import { PurchaseOrder } from "./types";
import { Client } from "../task/types";

interface CompaniesMap {
  [key: string]: Client;
}

const Modal: React.FC<{
  purchaseOrder: PurchaseOrder;
  setOpenModal: any;
  openModal: boolean;
  onCloseModal: () => void;
}> = ({
  purchaseOrder: currPurchaseOrder,
  setOpenModal,
  openModal,
  onCloseModal,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isEdit = Boolean(currPurchaseOrder.id);
  const emptyPO: PurchaseOrder = {
    id: 0,
    amount: 0,
    number: "",
    summary: "",
    attachments: [],
    company: null,
  };
  const {
    companyState: { companies },
  } = useSelector((state: RootStateOrAny) => state);
  const companiesMap: CompaniesMap = {};

  companies.forEach((company: Client) => {
    companiesMap[company.id] = company;
  });

  const [purchaseOrder, setPurchaseOrder] = useState(emptyPO);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [extFiles, setExtFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companyErr, setCompanyErr] = useState<boolean>(false);

  useEffect(() => {
    if (currPurchaseOrder.id) {
      setPurchaseOrder(currPurchaseOrder);
      setExtFiles(currPurchaseOrder.attachments || []);
    }
  }, [currPurchaseOrder, setExtFiles]);

  const uploadFiles = async (upFiles) => {
    const formData = new FormData();

    upFiles.forEach((file) => {
      formData.append("files", file);
    });

    const response = await axios.post("/upload", formData);

    return response.data;
  };

  const getFilesIds = async (files: any) => {
    const filesIds = [...extFiles].map((file) => file.id);

    if (files.length) {
      const uptFiles = await uploadFiles(files);

      uptFiles.forEach((upld) => {
        filesIds.push(upld.id);
      });

      setExtFiles([...extFiles, ...uptFiles]);
    }

    return filesIds;
  };

  const updateFiles = async (files: any) => {
    setLoading(true);

    const purchaseOrderToSave = {
      data: {
        id: currPurchaseOrder.id,
        attachments: await getFilesIds(files),
      },
    };

    dispatch(updatePOFiles(purchaseOrderToSave));
    setLoading(false);
  };

  const handleExtFileDelete = async (file) => {
    try {
      await axios.delete(`/upload/files/${file.id}`);
    } catch (e) {
      console.error("e", e);
    }

    const filesCopy = [...extFiles].filter((f) => f.id !== file.id);

    setExtFiles(filesCopy);
  };

  const handleDelete = (ticketId: any) => {
    dispatch(deletePurchaseOrder(ticketId));
    setOpenConfirmModal(false);
    setOpenModal(false);
    resetState();
    onCloseModal();
  };

  const handleOnChangeFormField = (event, name = null) => {
    if (name === null) {
      setPurchaseOrder({
        ...purchaseOrder,
        [event.target.name]: event.target.value,
      });
    } else {
      setPurchaseOrder({ ...purchaseOrder, [name]: event.toString() });
    }
  };

  const validatePurchaseOrder = () => {
    if (!purchaseOrder.company) {
      setCompanyErr(true);
      return false;
    } else if (!isPOnumberValid()) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!validatePurchaseOrder()) {
      setShowFormErrors(true);

      return null;
    }

    setLoading(true);

    setPurchaseOrder({ ...purchaseOrder });

    const purchaseOrderToSave = {
      data: {
        ...purchaseOrder,
        number: String(purchaseOrder.number),
        attachments: await getFilesIds(newFiles),
      },
    };

    if (isEdit) {
      await dispatch(updatePurchaseOrder(purchaseOrderToSave));
    } else {
      delete purchaseOrderToSave.data.id;
      await dispatch(createPurchaseOrder(purchaseOrderToSave));
    }

    resetState();
    onCloseModal();
  };

  const resetState = () => {
    setShowFormErrors(false);
    setPurchaseOrder(emptyPO);
    setOpenModal(false);
    setNewFiles([]);
    setExtFiles([]);
    setPurchaseOrder(emptyPO);
    setCompanyErr(false);
    setLoading(false);
  };

  const renderFiles = () => {
    return (
      <FormControl variant="outlined" sx={{ marginTop: "10px" }} fullWidth>
        <div>
          {newFiles.map((file, index) => {
            return (
              <Attachment
                key={index}
                mime={file.type}
                name={file.name}
                src={URL.createObjectURL(file)}
                deleteCallback={async () => {
                  const filesCopy = [...newFiles];
                  filesCopy.splice(index, 1);
                  setNewFiles(filesCopy);
                }}
              />
            );
          })}

          {extFiles.map((file, index) => {
            return (
              <Attachment
                key={index}
                mime={file.mime}
                name={file.name}
                src={(process.env.REACT_APP_FILES_URI || "") + file.url}
                deleteCallback={async () => {
                  setLoading(true);
                  await handleExtFileDelete(file);
                  setLoading(false);
                }}
              />
            );
          })}
        </div>
        <div
          style={{
            width: "100%",
            border: "1px dashed grey",
            position: "relative",
            height: 100,
            backgroundColor: "#fff",
            color: "grey",
            textAlign: "center",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloudUpload />
          <span style={{ marginTop: 4, marginLeft: 5 }}>
            <FormattedMessage
              id="general.Click-or-drop-files"
              defaultMessage="Click or drop files in the box to upload them"
            />
          </span>
          <input
            type="file"
            name="files"
            style={{
              width: "100%",
              height: 130,
              top: -30,
              left: 0,
              cursor: "pointer",
              position: "absolute",
            }}
            onChange={(e) => {
              const filesMap = e.target.files;
              const files = Object.keys(filesMap).map(
                (fileKey) => filesMap[fileKey]
              );

              if (isEdit) {
                updateFiles(files);
              } else {
                setNewFiles([...newFiles, ...files]);
              }

              e.target.value = null;
              e.target.files = null;
            }}
            multiple
          />
        </div>
      </FormControl>
    );
  };

  const isPOnumberValid = () => {
    const poNum = String(purchaseOrder.number);

    if (!poNum || poNum.length < 1) {
      return false;
    }

    return true;
  };

  const renderDialogActions = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 56.5,
            minWidth: 400,
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    return (
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 24px 20px",
        }}
      >
        <div>
          {isEdit && (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                setOpenConfirmModal(true);
              }}
              color="error"
            >
              <FormattedMessage id="general.Delete" defaultMessage="Delete" />
            </Button>
          )}
        </div>
        <div>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              resetState();
              onCloseModal();
            }}
          >
            <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
          </Button>
          <Button type="submit" color="primary">
            {isEdit ? (
              <FormattedMessage id="general.Update" defaultMessage="Update" />
            ) : (
              <FormattedMessage id="general.Create" defaultMessage="Create" />
            )}
          </Button>
        </div>
      </DialogActions>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          resetState();
          onCloseModal();
        }}
      >
        <DialogTitle>
          {isEdit ? (
            <FormattedMessage
              id="general.Edit-Purchase-Order"
              defaultMessage="Edit Purchase Order"
            />
          ) : (
            <FormattedMessage
              id="general.New-Purchase-Order"
              defaultMessage="New Purchase Order"
            />
          )}
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent
            style={{
              width: 1200,
              maxWidth: "100%",
            }}
          >
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel required id="company-label">
                    <FormattedMessage
                      id="general.Client"
                      defaultMessage="Client"
                    />
                  </InputLabel>
                  <Select
                    labelId="company-label"
                    label={
                      <FormattedMessage
                        id="general.Client"
                        defaultMessage="Client"
                      />
                    }
                    name="company"
                    required
                    onChange={(event) => {
                      const company = companiesMap[event.target.value];
                      if (company) {
                        setCompanyErr(false);
                        setPurchaseOrder({
                          ...purchaseOrder,
                          company,
                        });
                      }
                    }}
                    value={purchaseOrder.company?.id || ""}
                  >
                    <MenuItem value={0}>
                      <FormattedMessage
                        id="general.Select-client"
                        defaultMessage="Select client"
                      />
                    </MenuItem>
                    {companies.map((company) => {
                      return (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {companyErr && (
                    <FormHelperText sx={{ color: "#bf3333" }}>
                      <FormattedMessage
                        id="general.Client-is-required"
                        defaultMessage="Client is required"
                      />
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  value={purchaseOrder.number}
                  id="number"
                  label={
                    <FormattedMessage
                      id="general.Number"
                      defaultMessage="Number"
                    />
                  }
                  type="text"
                  name="number"
                  fullWidth
                  required
                  variant="outlined"
                  error={Boolean(showFormErrors && !isPOnumberValid())}
                  helperText={
                    Boolean(showFormErrors && !isPOnumberValid())
                      ? intl.formatMessage({
                          id: "general.Number-is-required",
                          defaultMessage: "Number is required",
                        })
                      : ""
                  }
                  onChange={(event) => {
                    handleOnChangeFormField(event);
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  value={purchaseOrder.amount || 0}
                  id="amount"
                  label={
                    <FormattedMessage
                      id="general.Amount"
                      defaultMessage="Amount"
                    />
                  }
                  type="number"
                  name="amount"
                  fullWidth
                  variant="outlined"
                  onChange={(event) => handleOnChangeFormField(event)}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  value={purchaseOrder.summary || ""}
                  id="summary"
                  label={
                    <FormattedMessage
                      id="general.Summary"
                      defaultMessage="Summary"
                    />
                  }
                  type="text"
                  name="summary"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  onChange={(event) => handleOnChangeFormField(event)}
                />
              </Grid>
            </Grid>
            {renderFiles()}
          </DialogContent>
          {renderDialogActions()}
        </Box>
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          handleDelete(currPurchaseOrder.id);
        }}
        text={intl.formatMessage({
          id: "purchase-order-modal.Do-you-really-want-to-delete-this-purchase-order",
          defaultMessage: "Do you really want to delete this purchase order?",
        })}
      />
    </div>
  );
};

export default Modal;
