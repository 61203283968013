import React, { useCallback, useEffect, useRef, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { IntlProvider } from "react-intl";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/bg";
import { Toaster } from "react-hot-toast";

import "./index.css";
import theme from "./theme";
import languages, { DEFAULT_LANGUAGE } from "./languages";
import { GlobalIntlProvider } from "./GlobalIntlProvider";
import { getOrganizations } from "./redux/reducerSlices/organization";
import { Elements as StripeElementsProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getOwnedOrganizations } from "./redux/reducerSlices/owned-organization";
import AppRoutes from "./Router";
import { Box, CircularProgress, Container } from "@mui/material";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const getMessages = (langCode: string = DEFAULT_LANGUAGE) => {
  return languages[langCode] || languages[DEFAULT_LANGUAGE];
};

const App = () => {
  const user = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const [isLoading, setIsloading] = useState(true);

  if (user?.jwt) {
    axios.defaults.baseURL = process.env.REACT_APP_API;
    axios.defaults.headers.common = {
      Authorization: `Bearer ${user?.jwt}`,
    };
  } else {
    axios.defaults.baseURL = process.env.REACT_APP_API;
    axios.defaults.headers.common = {};
  }

  const getUser = useCallback(async () => {
    try {
      const jwt = localStorage.getItem("jwt");

      if (jwt) {
        const me = await axios.get(`${process.env.REACT_APP_API}/users/me`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        const meData = me.data;

        dispatch({
          type: "LOGIN_USER",
          payload: {
            ...meData,
            jwt,
          },
        });
      } else {
        dispatch({
          type: "LOGOUT",
        });
      }
    } catch (e) {
      console.error(e);

      dispatch({
        type: "LOGOUT",
      });
    }

    setIsloading(false);
  }, [dispatch, setIsloading]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      getUser();
    }
  }, [getUser]);

  useEffect(() => {
    window.Trello.setKey(process.env.REACT_APP_TRELLO_KEY);
  }, []);

  useEffect(() => {
    if (user?.id) {
      dispatch(getOrganizations());
      dispatch(getOwnedOrganizations());
    }
  }, [dispatch, user?.id, user?.selected_organization_id]);

  useEffect(() => {
    moment.locale(user?.language || "bg");
  }, [user?.language]);

  if (isLoading) {
    return (
      <Container>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </Container>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        locale={user?.language || "bg"}
        defaultLocale="bg"
        messages={getMessages(user?.language)}
      >
        <GlobalIntlProvider>
          <StripeElementsProvider stripe={stripePromise}>
            <Router>
              <CssBaseline />
              <Toaster />
              <AppRoutes />
            </Router>
          </StripeElementsProvider>
        </GlobalIntlProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
