import axios from "axios";
import { toast } from "react-hot-toast";

let initialState = {};
const extUser = localStorage.getItem("user");

if (extUser) {
  initialState = {
    role: {},
    jwt: JSON.parse(extUser).jwt,
  };
}

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "LOGIN_USER":
    case "REFRESH_ME":
      const user = {
        ...state,
        ...action.payload,
      };

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("jwt", user.jwt);

      return user;
    case "LOGOUT":
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      return null;
    default:
      return state;
  }
};

export const changeLanguage = (langCode: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.put(`/users/me`, {
        language: langCode,
      });

      dispatch({
        type: "LOGIN_USER",
        payload: res.data,
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not change language. Reason: ${String(err)}`);
    }
  };
};

export const connectTrelloUser = ({
  trelloToken = "",
  trelloId = "",
  trelloMemberId = "",
}) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.put(`/users/me`, {
        trello_token: trelloToken,
        trello_id: trelloId,
        trello_member_id: trelloMemberId,
      });

      dispatch({
        type: "REFRESH_ME",
        payload: res.data,
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not change language. Reason: ${String(err)}`);
    }
  };
};

export const changeOrganization = (selected_organization_id: number) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.put(`/users/change-organization`, {
        selected_organization_id,
      });

      dispatch({
        type: "LOGIN_USER",
        payload: res.data,
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not change language. Reason: ${String(err)}`);
    }
  };
};

export default userReducer;
