const initialState = {
  openOrganizationModal: false,
  openProjectModal: false,
  modalProject: {},
};

const layoutReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "LAYOUT/UPDATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const updateLayout = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: "LAYOUT/UPDATE",
      payload,
    });
  };
};

export default layoutReducer;
