import React from "react";
import AppBar from "./components/AppBar";
import TasksDashboard from "./pages/TasksDashboard";
import TicketsPage from "./pages/Tickets";
import QuotesPage from "./pages/Quotes";
import InvoicesPage from "./pages/Invoices";
import UsersPage from "./pages/Users";
import SubscriptionsPage from "./pages/Subscriptions";
import Login from "./components/login/Login";
import OrganizationsPage from "./pages/Organizations";
import ProfilePage from "./pages/Profile";
import DashboardPage from "./pages/Dashboard";
import PurchaseOrdersPage from "./pages/PurchaseOrders";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { FormattedMessage } from "react-intl";

import "./index.css";
import ReleasesPage from "./pages/Releases";
import ProjectsPage from "./pages/Projects";
import CompaniesPage from "./pages/Companies";
import PrintQuote from "./pages/PrintQuote";
import ExpensesPage from "./pages/Expenses";

const PrivateRoute: React.FC<{
  component: React.FC;
  exact: Boolean;
  path: string;
  allow: any[];
  needsOrg?: boolean;
  showAppBar?: boolean;
}> = ({
  component: Component,
  allow,
  path,
  needsOrg = true,
  showAppBar = true,
  ...rest
}) => {
  const user = useSelector((state: any) => state.auth);
  const history = useHistory();

  if (!user?.id) {
    history.push("/");

    return null;
  }

  const orgRole = String(user.selectedOrg?.role).toLocaleLowerCase();
  const subscriptionStatus = user.selectedOrg?.subscription_status;

  if (needsOrg && !user.selectedOrg) {
    return (
      <Container>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormattedMessage
            id="general.You-need-to-select-an-organization-to-access-the-application"
            defaultMessage="You need to select an organization to access the application"
          />
          {JSON.stringify(user)}
        </Box>
      </Container>
    );
  }

  if (needsOrg && !["active", "past_due"].includes(subscriptionStatus)) {
    return (
      <Container>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormattedMessage
            id="general.Organization-is-currently-unavailable"
            defaultMessage="Organization is currently unavailable"
          />
        </Box>
      </Container>
    );
  }

  if (needsOrg && !allow.includes(orgRole)) {
    return (
      <Container>
        <Box
          sx={{
            mt: 2,
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormattedMessage
            id="general.user-cannot-access-route"
            defaultMessage="Only users with administrator privileges can access {path} at the moment"
            values={{
              path: path,
            }}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (user?.id) {
          if (showAppBar) {
            return (
              <>
                <AppBar />
                <Component {...props} />
              </>
            );
          }

          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute
        allow={["admin", "developer"]}
        exact
        path="/dashboard"
        component={() => <DashboardPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/projects"
        component={() => <ProjectsPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/quotes"
        component={() => <QuotesPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/invoices"
        component={() => <InvoicesPage />}
      />
      <PrivateRoute
        allow={["admin", "developer"]}
        exact
        path="/tasks*"
        component={() => <TasksDashboard />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/tickets"
        component={() => <TicketsPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/expenses"
        component={() => <ExpensesPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/releases"
        component={() => <ReleasesPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/companies"
        component={() => <CompaniesPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/purchase-orders"
        component={() => <PurchaseOrdersPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/subscriptions"
        component={() => <SubscriptionsPage />}
      />
      <PrivateRoute
        allow={["admin", "developer", "authenticated"]}
        needsOrg={false}
        exact
        path="/organizations"
        component={() => <OrganizationsPage />}
      />
      <PrivateRoute
        allow={["admin", "developer", "authenticated"]}
        needsOrg={false}
        exact
        path="/profile*"
        component={() => <ProfilePage />}
      />
      <PrivateRoute
        allow={["admin", "developer", "authenticated"]}
        exact
        path="/users"
        component={() => <UsersPage />}
      />
      <PrivateRoute
        allow={["admin", "developer", "authenticated"]}
        exact
        path="/print-quote/:id"
        showAppBar={false}
        component={() => <PrintQuote />}
      />
      {/* redirect all else to / */}
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
