import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Autocomplete,
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { TASK_STATUS } from "../../constants/constants";
import {
  createTask,
  deleteTask,
  getProjectActiveTasks,
  sawTask,
  updateTask,
} from "../../redux/reducerSlices/task";
import ConfirmDialog from "../ConfirmDialog";
import { localizeTaskStatus } from "./functions";

const emptyTask = {
  title: "",
  description: "",
  status: "Gathering Data",
  project: {
    id: "",
    name: "",
    trello_id: "",
    labels: [],
  },
  preliminary_notes: "",
  invoicing_scheme: "",
  developer: {} as any,
  blocking_tasks: [],
  blocked_by_tasks: [],
  blocked: false,
  label: {
    id: "",
    name: "",
    type: "",
    color: "",
  },
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const CreateTask: React.FC<{
  task?: any;
  setOpenModal: any;
  openModal: boolean;
  projects: [any];
  taskFromQi?: any;
  createCallback?: (task: any) => void;
  updateCallback?: (task: any) => void;
  deleteCallback?: (task: any) => void;
}> = ({
  task: currTask,
  setOpenModal,
  openModal,
  projects,
  taskFromQi,
  createCallback,
  updateCallback,
  deleteCallback,
}) => {
  const isEdit = Boolean(currTask?.id);
  const isEditFromQi = Boolean(taskFromQi?.id);
  const {
    user,
    task: { activeTasks, activeTasksProjectId },
  } = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const intl = useIntl();
  const query = useQuery();

  const [task, setTask] = useState(emptyTask);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const projectIdQuery = query.get("projectId") || "";
  const selectedProject = projects.find(
    (project) => project.id === task.project?.id
  );

  useEffect(() => {
    if (!selectedProject?.id) return null;

    if (activeTasksProjectId !== selectedProject?.id) {
      dispatch(getProjectActiveTasks(selectedProject.id));
    }
  }, [dispatch, selectedProject?.id, activeTasksProjectId]);

  useEffect(() => {
    if (!openModal) return null;

    if (isEdit) {
      setTask(currTask);

      if (!currTask.seen_by?.length) {
        dispatch(sawTask(currTask.id));
      }
    } else {
      setTask(emptyTask);
    }
  }, [currTask, isEdit, dispatch, openModal]);

  useEffect(() => {
    if (!openModal) return null;

    if (taskFromQi) {
      setTask({
        ...taskFromQi,
      });
    }
  }, [taskFromQi, openModal]);

  const updateTaskProjectFromQuery = React.useCallback(() => {
    if (!openModal) return null;

    if (projectIdQuery) {
      const project = projects.find(
        (project) => project.id === parseInt(projectIdQuery)
      );

      if (project) {
        setTask((task) => ({
          ...task,
          project,
          label: project.labels[0],
        }));
      }
    }
  }, [projectIdQuery, projects, openModal]);

  useEffect(() => {
    updateTaskProjectFromQuery();
  }, [projectIdQuery, updateTaskProjectFromQuery]);

  const handleDelete = (taskId: any) => {
    dispatch(deleteTask(taskId, task));
    setOpenConfirmModal(false);
    setOpenModal(false);

    if (deleteCallback) {
      deleteCallback(task);
    }
  };

  const handleOnChangeFormField = (event, name = null) => {
    if (name === null) {
      setTask({ ...task, [event.target.name]: event.target.value });
    } else {
      setTask({ ...task, [name]: event.toString() });
    }
  };

  const validateTask = () => {
    if (!task.title) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateTask()) {
      setShowFormErrors(true);
      return null;
    }

    let taskPayload = {
      ...task,
      label: task.label?.id ? task.label : null,
    };

    if (selectedProject?.developers.length === 1) {
      taskPayload = {
        ...taskPayload,
        developer: selectedProject.developers[0],
      };
    } else if (!taskPayload.developer?.id) {
      taskPayload = {
        ...taskPayload,
        developer: null,
      };
    }

    const taskToSave = {
      data: {
        ...taskPayload,
      },
    };

    if (isEdit || isEditFromQi) {
      await dispatch(updateTask(taskToSave));

      if (updateCallback) {
        updateCallback(taskPayload);
      }
    } else {
      await dispatch(
        createTask(taskToSave, (createdTask) => {
          if (createCallback) {
            createCallback(createdTask);
          }
        })
      );
    }

    setTask(emptyTask);
    setShowFormErrors(false);
    setOpenModal(false);
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          setShowFormErrors(false);
          setOpenModal(false);
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {isEdit || isEditFromQi ? (
            <FormattedMessage
              id="task-modal.Edit-Task"
              defaultMessage="Edit Task"
            />
          ) : (
            <FormattedMessage
              id="task-modal.New-Task"
              defaultMessage="New Task"
            />
          )}
          <ToggleButtonGroup size="small">
            {task.project?.labels?.map((label) => (
              <ToggleButton
                key={label.id}
                value={label}
                style={{
                  color: task.label?.id === label.id ? label.color : "",
                }}
                selected={task.label?.id === label.id}
                onClick={() => {
                  setTask({ ...task, label });
                }}
              >
                {label.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="project-label">
                    <FormattedMessage
                      id="general.Project"
                      defaultMessage="Project"
                    />
                  </InputLabel>
                  <Select
                    labelId="project-label"
                    label={intl.formatMessage({
                      id: "general.Project",
                      defaultMessage: "Project",
                    })}
                    name="project"
                    onChange={(event: any) => {
                      setTask({
                        ...task,
                        project: event.target.value,
                        label: event.target.value?.labels[0],
                        invoicing_scheme:
                          event.target.value?.default_invoicing_scheme ||
                          "None",
                      });
                    }}
                    value={task.project}
                    renderValue={(project) => <span>{project.name}</span>}
                    disabled={Boolean(currTask?.project?.trello_id)}
                  >
                    {projects.map((project) => {
                      return (
                        <MenuItem key={project.id} value={project}>
                          {project.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="status-label">
                    <FormattedMessage
                      id="general.Status"
                      defaultMessage="Status"
                    />
                  </InputLabel>
                  <Select
                    labelId="status-label"
                    label={intl.formatMessage({
                      id: "general.Status",
                      defaultMessage: "Status",
                    })}
                    name="status"
                    onChange={(event) => handleOnChangeFormField(event)}
                    value={task.status}
                    defaultValue="Gathering Data"
                    error={showFormErrors && task.status === ""}
                  >
                    {TASK_STATUS.map((taskStatus, i) => {
                      return (
                        <MenuItem
                          key={taskStatus.value + i}
                          value={taskStatus.value}
                        >
                          <span
                            style={{
                              backgroundColor: taskStatus.color.background,
                              borderRadius: 5,
                              color: taskStatus.color.text,
                              padding: "2px 6px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {localizeTaskStatus(taskStatus.label)}
                          </span>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              autoFocus
              value={task.title}
              style={{ marginTop: "10px" }}
              id="title"
              label={intl.formatMessage({
                id: "general.Title",
                defaultMessage: "Title",
              })}
              type="text"
              name="title"
              fullWidth
              required
              variant="outlined"
              error={showFormErrors && task.title === ""}
              helperText={
                showFormErrors && task.title === ""
                  ? intl.formatMessage({
                      id: "general.Title-is-required",
                      defaultMessage: "Title is required",
                    })
                  : ""
              }
              onChange={(event) => handleOnChangeFormField(event)}
            />
            <TextField
              style={{ width: "100%", marginTop: "10px", position: "relative" }}
              id="description"
              name="description"
              rows={5}
              InputProps={{
                inputComponent: TextareaAutosize,
                rows: 5,
                name: "description",
                value: task.description || "",
                onChange: (event) => handleOnChangeFormField(event),
                minRows: 5,
                inputProps: {
                  style: {
                    width: "calc(100% - 28px)",
                    minWidth: "calc(100% - 28px)",
                    minHeight: "140px",
                  },
                },
              }}
              // value={task.description || ""}
              label={intl.formatMessage({
                id: "general.Description",
                defaultMessage: "Description",
              })}
              type="text"
              fullWidth
              variant="outlined"
              // onChange={(event) => handleOnChangeFormField(event)}
            />
            <FormControl
              variant="outlined"
              sx={{ marginTop: "10px" }}
              fullWidth
            >
              <Autocomplete
                multiple
                id="select-blockers"
                disableCloseOnSelect={true}
                options={activeTasks}
                getOptionLabel={(t) => t.title}
                value={task.blocking_tasks || []}
                onChange={(event: any, newValue) => {
                  if (event.key === "Backspace") return null;

                  setTask({
                    ...task,
                    blocking_tasks: newValue,
                  });
                }}
                // renderTags={() => null}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "general.Blocking-tasks",
                      defaultMessage: "Blocking tasks",
                    })}
                    placeholder={intl.formatMessage({
                      id: "general.Filter-tasks-by-title",
                      defaultMessage: "Filter tasks by title",
                    })}
                  />
                )}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              sx={{ marginTop: "10px" }}
              fullWidth
            >
              <Autocomplete
                multiple
                id="select-bocked-by"
                disableCloseOnSelect={true}
                options={activeTasks}
                getOptionLabel={(t) => t.title}
                value={task.blocked_by_tasks || []}
                onChange={(event: any, newValue) => {
                  if (event.key === "Backspace") return null;

                  setTask({
                    ...task,
                    blocked_by_tasks: newValue,
                  });
                }}
                // renderTags={() => null}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "general.Blocked-by-tasks",
                      defaultMessage: "Blocked by tasks",
                    })}
                    placeholder={intl.formatMessage({
                      id: "general.Filter-tasks-by-title",
                      defaultMessage: "Filter tasks by title",
                    })}
                  />
                )}
              />
            </FormControl>
            {user.selected_organization_role === "Admin" && (
              <FormControl
                variant="outlined"
                sx={{ marginTop: "10px" }}
                fullWidth
              >
                <InputLabel id="invoicing_scheme-label">
                  <FormattedMessage
                    id="general.Invoicing-scheme"
                    defaultMessage="Invoicing scheme"
                  />
                </InputLabel>
                <Select
                  labelId="invoicing_scheme-label"
                  label={intl.formatMessage({
                    id: "general.Invoicing-scheme",
                    defaultMessage: "Invoicing scheme",
                  })}
                  name="invoicing_scheme"
                  onChange={(event) => handleOnChangeFormField(event)}
                  value={task.invoicing_scheme || "open"}
                  error={showFormErrors && task.invoicing_scheme === ""}
                >
                  <MenuItem value="Fixed">
                    <FormattedMessage
                      id="general.Fixed"
                      defaultMessage="Fixed"
                    />
                  </MenuItem>
                  <MenuItem value="Hourly">
                    <FormattedMessage
                      id="general.Hourly"
                      defaultMessage="Hourly"
                    />
                  </MenuItem>
                  <MenuItem value="Cost">
                    <FormattedMessage id="general.Cost" defaultMessage="Cost" />
                  </MenuItem>
                  <MenuItem value="None">
                    <FormattedMessage id="general.None" defaultMessage="None" />
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            {selectedProject && (
              <FormControl
                variant="outlined"
                sx={{ marginTop: "10px" }}
                fullWidth
              >
                <InputLabel id="developer-label">
                  <FormattedMessage
                    id="general.Developer"
                    defaultMessage="Developer"
                  />
                </InputLabel>
                <Select
                  labelId="developer-label"
                  label={intl.formatMessage({
                    id: "general.Developer",
                    defaultMessage: "Developer",
                  })}
                  name="developer"
                  onChange={(event) => handleOnChangeFormField(event)}
                  value={task?.developer || ""}
                  renderValue={(developer) => <span>{developer.email}</span>}
                >
                  <MenuItem value="">
                    <em>
                      <FormattedMessage
                        id="general.No-developer"
                        defaultMessage="No developer"
                      />
                    </em>
                  </MenuItem>
                  {selectedProject.developers.map((developer) => {
                    return (
                      <MenuItem key={developer.id} value={developer}>
                        {developer.email}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            <TextField
              multiline
              rows={2}
              style={{ width: "100%" }}
              margin="dense"
              id="preliminary_notes"
              name="preliminary_notes"
              value={task.preliminary_notes || ""}
              label={intl.formatMessage({
                id: "task-modal.Preliminary-release-notes",
                defaultMessage: "Preliminary release notes",
              })}
              type="text"
              fullWidth
              variant="outlined"
              onChange={(event) => handleOnChangeFormField(event)}
            />
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 24px 20px",
            }}
          >
            <div>
              {isEdit && (
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenConfirmModal(true);
                  }}
                  color="error"
                >
                  <FormattedMessage
                    id="general.Delete-Task"
                    defaultMessage="Delete Task"
                  />
                </Button>
              )}
            </div>
            <div>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  setShowFormErrors(false);
                  setOpenModal(false);
                }}
              >
                <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {isEdit ? (
                  <FormattedMessage
                    id="general.Update"
                    defaultMessage="Update"
                  />
                ) : (
                  <FormattedMessage
                    id="general.Create"
                    defaultMessage="Create"
                  />
                )}
              </Button>
            </div>
          </DialogActions>
        </Box>
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          handleDelete(currTask.id);
        }}
        text={intl.formatMessage(
          {
            id: "task-modal.confirm-task-deletion",
            defaultMessage:
              'Do you really want to delete the task "{task_title}"? This will also delete any quote items associated with it!',
          },
          {
            task_title: task.title,
          }
        )}
      />
    </div>
  );
};

export default CreateTask;
