import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";

import ConfirmDialog from "../ConfirmDialog";
import { Subscription } from "./types";
import {
  createSubscription,
  deleteSubscription,
  updateSubscription,
} from "../../redux/reducerSlices/subscription";
import { Client } from "../task/types";

interface ClientsMap {
  [key: string]: Client;
}

const emptySubscription: Subscription = {
  id: null,
  title: "",
  active: true,
  start_date: new Date().toISOString().split("T")[0],
  last_terms_update: new Date().toISOString().split("T")[0],
  renewal_frequency: "monthly",
  price: 0,
  renewal_notice_days: 14,
};

const SubscriptionModal: React.FC<{
  extSubscription?: Subscription | null;
  setOpenModal: any;
  openModal: boolean;
  onSubmitCB?: () => void;
}> = ({ extSubscription, setOpenModal, openModal, onSubmitCB }) => {
  const isEditingSubscription = Boolean(extSubscription?.id);
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    companyState: { companies },
  } = useSelector((state: any) => state);

  const [subscription, setSubscription] =
    useState<Subscription>(emptySubscription);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [companyErr, setCompanyErr] = useState<boolean>(false);
  const [titleErr, setTitleErr] = useState<boolean>(false);
  const [priceErr, setPriceErr] = useState<boolean>(false);

  const companiesMap: ClientsMap = {};

  companies.forEach((company: Client) => {
    companiesMap[company.id] = company;
  });

  useEffect(() => {
    if (!openModal) return null;

    if (extSubscription) {
      setSubscription({
        ...extSubscription,
      });
    }
  }, [extSubscription, setSubscription, openModal]);

  const closeModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setSubscription(emptySubscription);
      setCompanyErr(false);
      setTitleErr(false);
      setPriceErr(false);
    }, 200);
  };

  const handleDelete = (subscription: Subscription) => {
    dispatch(deleteSubscription(subscription.id));
    setOpenConfirmModal(false);
    closeModal();
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setSubscription({
        ...subscription,
        [event.target.name]: event.target.value,
      });
    } else {
      setSubscription({
        ...subscription,
        [name]: event.toString(),
      });
    }
  };

  const validateSubscription = () => {
    let valid = true;

    if (!subscription.company) {
      setCompanyErr(true);
      valid = false;
    }

    if (!subscription.title) {
      setTitleErr(true);
      valid = false;
    }

    if (!subscription.price) {
      setPriceErr(true);
      valid = false;
    }

    return valid;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateSubscription()) {
      return null;
    }

    setLoading(true);

    if (isEditingSubscription) {
      await dispatch(
        updateSubscription({
          ...subscription,
        })
      );
    } else {
      const newSubscription = {
        ...subscription,
      };

      delete newSubscription.id;

      await dispatch(createSubscription(newSubscription));
    }

    if (onSubmitCB) {
      onSubmitCB();
    }

    setLoading(false);
    closeModal();
  };

  const getModalTitle = () => {
    return isEditingSubscription ? (
      <FormattedMessage
        id="subscription-modal.Edit-Subscription"
        defaultMessage="Edit Subscription"
      />
    ) : (
      <FormattedMessage
        id="general.New-Subscription"
        defaultMessage="New Subscription"
      />
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: 600,
              maxWidth: "100%",
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            style={{
              width: 600,
              maxWidth: "100%",
            }}
          >
            <DialogContent>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    id="title"
                    name="title"
                    value={subscription.title || ""}
                    label={intl.formatMessage({
                      id: "general.Title",
                      defaultMessage: "Title",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => {
                      setTitleErr(false);
                      handleOnChangeFormField(event);
                    }}
                  />
                  {titleErr && (
                    <FormHelperText sx={{ color: "#bf3333" }}>
                      <FormattedMessage
                        id="general.Title-is-required"
                        defaultMessage="Title is required"
                      />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="company-label">
                      <FormattedMessage
                        id="general.Client"
                        defaultMessage="Client"
                      />
                    </InputLabel>
                    <Select
                      labelId="company-label"
                      label={
                        <FormattedMessage
                          id="general.Client"
                          defaultMessage="Client"
                        />
                      }
                      name="company"
                      error={companyErr}
                      onChange={(event) => {
                        const company = companiesMap[event.target.value];
                        if (company) {
                          setCompanyErr(false);
                          setSubscription({
                            ...subscription,
                            company,
                          });
                        }
                      }}
                      value={subscription.company?.id || ""}
                    >
                      <MenuItem value={0}>
                        <FormattedMessage
                          id="general.Select-client"
                          defaultMessage="Select client"
                        />
                      </MenuItem>
                      {companies.map((company) => {
                        return (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {companyErr && (
                      <FormHelperText sx={{ color: "#bf3333", marginLeft: 0 }}>
                        <FormattedMessage
                          id="general.Client-is-required"
                          defaultMessage="Client is required"
                        />
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="currency-label">
                      <FormattedMessage
                        id="general.Renewal-Frequency"
                        defaultMessage="Renewal-Frequency"
                      />
                    </InputLabel>
                    <Select
                      fullWidth
                      id="renewal_frequency"
                      name="renewal_frequency"
                      value={subscription.renewal_frequency || ""}
                      label={intl.formatMessage({
                        id: "general.Renewal-Frequency",
                        defaultMessage: "Renewal-Frequency",
                      })}
                      type="text"
                      variant="outlined"
                      onChange={(event) => handleOnChangeFormField(event)}
                    >
                      <MenuItem value="monthly">
                        <FormattedMessage
                          id="general.monthly"
                          defaultMessage="monthly"
                        />
                      </MenuItem>
                      <MenuItem value="yearly">
                        <FormattedMessage
                          id="general.yearly"
                          defaultMessage="yearly"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="price"
                    name="price"
                    value={subscription.price || ""}
                    label={intl.formatMessage({
                      id: "general.Price",
                      defaultMessage: "Price",
                    })}
                    type="number"
                    variant="outlined"
                    onChange={(event) => {
                      setPriceErr(false);
                      handleOnChangeFormField(event);
                    }}
                  />
                  {priceErr && (
                    <FormHelperText sx={{ color: "#bf3333" }}>
                      <FormattedMessage
                        id="general.Price-is-required"
                        defaultMessage="Price is required and cannot be 0"
                      />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="start_date"
                    name="start_date"
                    value={subscription.start_date || ""}
                    label={intl.formatMessage({
                      id: "general.Start-Date",
                      defaultMessage: "Start-Date",
                    })}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event: any) => {
                      setSubscription({
                        ...subscription,
                        start_date: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="last_terms_update"
                    name="last_terms_update"
                    value={subscription.last_terms_update || ""}
                    label={intl.formatMessage({
                      id: "general.Last-term-update",
                      defaultMessage: "Last term update",
                    })}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event: any) => {
                      setSubscription({
                        ...subscription,
                        last_terms_update: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="renewal_notice_days"
                    name="renewal_notice_days"
                    value={subscription.renewal_notice_days || ""}
                    label={intl.formatMessage({
                      id: "general.Renewal-notice-days",
                      defaultMessage: "Renewal notice days",
                    })}
                    type="number"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={subscription.active || false}
                        onChange={(event) => {
                          setSubscription({
                            ...subscription,
                            active: event.target.checked ? true : false,
                          });
                        }}
                        name="active"
                        color="primary"
                      />
                    }
                    label={intl.formatMessage({
                      id: "general.Active",
                      defaultMessage: "Active",
                    })}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEditingSubscription && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    <FormattedMessage
                      id="general.Delete-Subscription"
                      defaultMessage="Delete Subscription"
                    />
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  <FormattedMessage
                    id="general.Cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  {isEditingSubscription ? (
                    <FormattedMessage
                      id="general.Update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="general.Create"
                      defaultMessage="Create"
                    />
                  )}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditingSubscription) {
            handleDelete(subscription);
          }
        }}
        text={intl.formatMessage({
          id: "subscription-modal.Do-you-really-want-to-delete-subscription",
          defaultMessage: "Do you really want to delete the subscription?",
        })}
      />
    </div>
  );
};

export default SubscriptionModal;
