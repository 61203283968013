import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AddCircle, Delete } from "@mui/icons-material";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ConfirmDialog from "../ConfirmDialog";
import {
  Invoice,
  InvoiceStatus,
  Currency,
  Task,
  Client,
  InvoiceItem,
  TaskRow,
  PurchaseOrder,
  PurchaseOrdersMap,
} from "../task/types";
import {
  createInvoice,
  deleteInvoice,
  deleteInvoiceItem,
  updateInvoice,
} from "../../redux/reducerSlices/invoice";
import InvoiceItemRow from "./InvoiceItemRow";
import CreateTask from "../task/CreateTask";
import { refreshTasks } from "../../redux/reducerSlices/task";
import { getInvoiceTitle, truncate } from "../task/functions";
import CompaniesAutocomplete from "../company/CompaniesAutocomplete";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: `0px 0px 3px`,
  margin: `0 0 2px 0`,
  borderRadius: 5,
  background: "white",

  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightgrey" : "white",
  padding: grid,
});

const emptyInvoice = {
  number: 0,
  date: new Date(),
  currency: Currency.BGN,
  status: InvoiceStatus.draft,
  company: {
    id: 0,
  },
  purchase_order: {},
} as Invoice;

interface CompaniesMap {
  [key: string]: Client;
}

const InvoiceModal: React.FC<{
  extInvoice?: Invoice | null;
  task?: TaskRow | null;
  setOpenModal: any;
  openModal: boolean;
  company?: Client | null;
  onSubmitCB?: () => void;
}> = ({ extInvoice, task, setOpenModal, openModal, company, onSubmitCB }) => {
  const hasTask = Boolean(task);

  const isEditingInvoice = Boolean(extInvoice);
  const {
    project: { projectOptions },
    purchaseOrderState,
    user,
    companyState,
  } = useSelector((state: RootStateOrAny) => state);
  const { purchaseOrdersNotFullyInvoiced } = purchaseOrderState;
  const invoiceTotal = extInvoice?.invoice_items.reduce(
    (n, { price }) => n + price,
    0
  );
  const { companies } = companyState;
  const companiesMap: CompaniesMap = {};

  companies.forEach((company: Client) => {
    companiesMap[company.id] = company;
  });

  const dispatch = useDispatch();
  const intl = useIntl();

  const [invoice, setInvoice] = useState<Invoice>(emptyInvoice);
  const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openConfirmModalItem, setOpenConfirmModalItem] =
    useState<boolean>(false);
  const [companyErr, setCompanyErr] = useState<boolean>(false);
  const [iiToDelete, setIiToDelete] = useState<any>({});
  const [iis, setIis] = useState<InvoiceItem[]>([]);
  const [lastInvoiceNum, setLastInvoiceNum] = useState<number>(0);
  const [modalTask, setModalTask] = useState<Task>(null);
  const [openTaskModal, setOpenTaskModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [purchaseOrdersMap, setPurchaseOrdersMap] = useState<PurchaseOrdersMap>(
    {}
  );
  const [poReqErr, setPoReqErr] = useState<boolean>(false);
  const [newIIid, setNewIIid] = useState<number>(0);

  useEffect(() => {
    if (openModal) {
      const getLastInvoiceNum = async () => {
        const response = await axios.get(
          "/invoices?sort[0]=number:desc&pagination[limit]"
        );
        const lastInvoiceNum = parseInt(
          response.data?.data[0]?.attributes?.number || 0
        );

        setLastInvoiceNum(lastInvoiceNum + 1);
        setInvoice((invoice) => ({
          ...invoice,
          number: invoice.number || lastInvoiceNum + 1,
        }));
      };

      getLastInvoiceNum();
    }

    setCompanyErr(false);
    setPoReqErr(false);
  }, [openModal]);

  useEffect(() => {
    if (extInvoice) {
      setInvoice({
        ...extInvoice,
        date: new Date(extInvoice.date),
      });

      setIis([...(extInvoice.invoice_items || [])]);
    }
  }, [extInvoice, setInvoice]);

  useEffect(() => {
    if (company) {
      setInvoice((invoice) => ({
        ...invoice,
        company,
        currency: company.currency,
        invoice_to: company.invoice_to,
        markup_percentage: company.markup_percentage,
      }));
    }
  }, [company, task]);

  useEffect(() => {
    if (!openModal || !task) return;

    let discountIi: InvoiceItem = null;

    if (company?.invoice_to && company?.markup_percentage) {
      discountIi = {
        id: -9000,
        price: 0,
        title: "Discount",
        order: 0,
        is_markup_discount: true,
      };
    }

    if (task.subscription_items?.length) {
      let currId = -1000;
      const newIis: InvoiceItem[] = [];

      task.subscription_items.forEach((subscrItem: any, index) => {
        currId--;

        newIis.push({
          id: currId,
          price: subscrItem.price,
          subscription_item: subscrItem,
          title: subscrItem.title,
          order: index + 1,
        });
      });

      if (discountIi) {
        newIis.push(discountIi);
      }

      setIis(newIis);
      setInvoice((invoice) => ({
        ...invoice,
        company,
        invoice_items: newIis,
        currency: company?.currency || Currency.BGN,
        invoice_to: company?.invoice_to,
        markup_percentage: company?.markup_percentage,
      }));
    } else {
      const taskFromRow: Task = {
        id: task.id || 0,
        status: task.status,
        title: task.title,
        seen_by: task.seen_by,
      };

      let price = 0;

      if (task.partial_invoicing) {
        if (task.status === "Closed") {
          price = task.quoted_total - task.invoiced_total;
        } else {
          price = task.quoted_total * (task.partial_invoicing / 100);
        }
      }

      const newIi: InvoiceItem = {
        id: -1000,
        price,
        task: taskFromRow,
        title: company ? getInvoiceTitle(task, company) : task.title,
        order: 0,
      };

      const newIis: InvoiceItem[] = [newIi];

      if (discountIi) {
        newIis.push(discountIi);
      }

      setIis(newIis);
      setInvoice((invoice) => ({
        ...invoice,
        company,
        currency: company?.currency || Currency.BGN,
        invoice_items: newIis,
        invoice_to: company?.invoice_to,
        markup_percentage: company?.markup_percentage,
      }));
    }
  }, [hasTask, task, openModal, company]);

  useEffect(() => {
    const getPOs = async () => {
      const poMap: PurchaseOrdersMap = {};

      purchaseOrdersNotFullyInvoiced.forEach((po: PurchaseOrder) => {
        poMap[po.id] = po;
      });

      setPurchaseOrdersMap(poMap);
    };

    getPOs();
  }, [invoiceTotal, purchaseOrdersNotFullyInvoiced]);

  const closeModal = () => {
    setShowFormErrors(false);
    setOpenModal(false);

    setTimeout(() => {
      setInvoice(emptyInvoice);
      setIis([]);
    }, 200);
  };

  const handleDelete = async (invoice: Invoice) => {
    await dispatch(deleteInvoice(invoice.id));

    if (onSubmitCB) onSubmitCB();

    setOpenConfirmModal(false);
    setOpenModal(false);
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setInvoice({
        ...invoice,
        [event.target.name]: event.target.value,
      });
    } else {
      setInvoice({
        ...invoice,
        [name]: event.toString(),
      });
    }
  };

  const validateInvoice = () => {
    if (!invoice.number && !lastInvoiceNum) {
      return false;
    } else if (!invoice.company?.id) {
      setCompanyErr(true);

      return false;
    } else if (invoice.company?.requires_po && !invoice.purchase_order?.id) {
      setPoReqErr(true);

      return false;
    }

    return true;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateInvoice()) {
      setShowFormErrors(true);

      return null;
    }

    setLoading(true);

    if (isEditingInvoice) {
      await dispatch(
        updateInvoice({
          data: {
            ...invoice,
          },
        })
      );
    } else {
      const newInvoice = {
        ...invoice,
        number: invoice.number || lastInvoiceNum,
        invoice_items: iis,
      };

      delete newInvoice.id;

      await dispatch(
        createInvoice({
          invoice: newInvoice,
        })
      );

      if (hasTask) dispatch(refreshTasks());
    }

    if (onSubmitCB) onSubmitCB();

    setLoading(false);
    closeModal();
  };

  const getModalTitle = () => {
    return isEditingInvoice ? (
      <FormattedMessage
        id="invoice-modal.Edit-Invoice"
        defaultMessage="Edit Invoice"
      />
    ) : (
      <FormattedMessage
        id="invoice-modal.New-Invoice"
        defaultMessage="New Invoice"
      />
    );
  };

  const handleMarkupDiscount = (invoice: Invoice) => {
    const { markup_percentage, invoice_items: iis } = invoice;

    if (!(invoice.invoice_to || markup_percentage)) {
      const newIis = iis.filter((ii) => !ii.is_markup_discount);

      setIis(newIis);
      setInvoice((prevInvoice) => {
        const uptInvoice = {
          ...prevInvoice,
          invoice_items: newIis,
        };

        return uptInvoice;
      });

      return null;
    }

    const discountItem = iis.find((ii) => ii.is_markup_discount);
    const total =
      iis
        .filter((ii) => !ii.is_markup_discount)
        .reduce((n, { price: p }) => n + parseFloat(`${p}`), 0) || 0;

    const price = -(total * (1 - 100 / (100 + markup_percentage)));

    if (discountItem) {
      const newIis = iis.map((ii) => {
        if (ii.id === discountItem.id) {
          ii.price = Number(price.toFixed(2));
        }

        return ii;
      });

      setIis(newIis);
      setInvoice((prevInvoice) => {
        const uptInvoice = {
          ...prevInvoice,
          invoice_items: newIis,
        };

        return uptInvoice;
      });
    } else {
      const newIi: InvoiceItem = {
        id: -9000,
        price,
        title: "Discount",
        order: iis.length + 1,
        is_markup_discount: true,
      };

      setIis([...iis, newIi]);
      setInvoice((prevInvoice) => {
        const uptInvoice = {
          ...prevInvoice,
          invoice_items: [...iis, newIi],
        };

        return uptInvoice;
      });
    }
  };

  const openConfirmItemModal = (ii: any) => {
    setOpenConfirmModalItem(true);
    setIiToDelete(ii);
  };

  const handleDeleteInvoiceItem = (delIi: InvoiceItem) => {
    const uptIis: InvoiceItem[] = [...iis].filter((ii) => ii.id !== delIi.id);

    if (delIi.id > 0) {
      dispatch(deleteInvoiceItem(delIi.id));
    }

    setIis(uptIis);
    setInvoice((prevInvoice) => {
      const uptInvoice = {
        ...prevInvoice,
        invoice_items: uptIis,
      };

      handleMarkupDiscount(uptInvoice);

      return uptInvoice;
    });

    setOpenConfirmModalItem(false);
  };

  const updateIi = (
    uptIi: InvoiceItem,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const uptIis = [...iis].map((ii) => {
      if (ii.id === uptIi.id) {
        ii[event.target.name] = event.target.value;
      }

      return ii;
    });

    setIis(uptIis);
    handleMarkupDiscount({
      ...invoice,
      invoice_items: uptIis,
    });
  };

  const handleOpenTaskModal = (task: Task) => {
    setModalTask(task);
    setOpenTaskModal(true);
  };

  const handleUpdateTaskCallback = (task: any) => {
    const uptIis = [...iis].map((ii) => {
      if (ii.task.id === task.id) {
        ii.task = task;
      }

      return ii;
    });

    setIis(uptIis);
  };

  const handleDeleteTaskCallback = (task: any) => {
    const uptIis = [...iis].filter((ii) => {
      if (ii.task.id !== task.id) return true;

      dispatch(deleteInvoiceItem(ii.id));

      return false;
    });

    setIis(uptIis);
  };

  const getTotalSum = () => {
    const total =
      iis?.reduce((n, { price: p }) => n + parseFloat(`${p}`), 0) || 0;

    if (total % 1 !== 0) {
      return total.toFixed(2);
    }

    return total;
  };

  const renderStatus = (status) => {
    switch (status) {
      case "draft":
        return (
          <FormattedMessage id="general-status.draft" defaultMessage="draft" />
        );
      case "sent":
        return (
          <FormattedMessage id="general-status.sent" defaultMessage="sent" />
        );
      case "closed":
        return (
          <FormattedMessage
            id="general-status.closed"
            defaultMessage="closed"
          />
        );
      default:
        console.error("unexpected status: ", status);
        return (
          <FormattedMessage
            id="general-status.unexpected-status"
            defaultMessage="unexpected status"
          />
        );
    }
  };

  const onDragEnd = (event: any) => {
    if (!event.destination) {
      return null;
    }

    const items = reorder(
      iis,
      event.source.index,
      event.destination.index
    ) as any;

    setIis(
      items.map((ii, index) => {
        ii.order = index + 1;

        return ii;
      })
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: "100%",
              minWidth: 600,
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item sm={4}>
                  <TextField
                    value={invoice.number || lastInvoiceNum}
                    id="number"
                    label={
                      <FormattedMessage
                        id="general.Number"
                        defaultMessage="Number"
                      />
                    }
                    type="number"
                    name="number"
                    fullWidth
                    required
                    variant="outlined"
                    error={
                      Boolean(showFormErrors && invoice.number === 0) &&
                      lastInvoiceNum === 0
                    }
                    helperText={
                      Boolean(showFormErrors && invoice.number === 0)
                        ? intl.formatMessage({
                            id: "general.Invoice-number-is-required",
                            defaultMessage: "Invoice number is required",
                          })
                        : ""
                    }
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="company-label">
                      <FormattedMessage
                        id="general.Client"
                        defaultMessage="Client"
                      />
                    </InputLabel>
                    <Select
                      labelId="company-label"
                      label={
                        <FormattedMessage
                          id="general.Client"
                          defaultMessage="Client"
                        />
                      }
                      name="company"
                      error={companyErr}
                      onChange={(event) => {
                        const company = companiesMap[event.target.value];
                        setPoReqErr(false);

                        if (company) {
                          setCompanyErr(false);
                          setIis(
                            iis.map((ii) => ({
                              ...ii,
                              title: task
                                ? getInvoiceTitle(task, company)
                                : ii.title,
                            }))
                          );

                          setInvoice((prevInvoice) => {
                            let extDiscountIi = prevInvoice.invoice_items?.find(
                              (ii) => ii.is_markup_discount && ii.id > 0
                            );

                            if (extDiscountIi) {
                              dispatch(
                                deleteInvoiceItem(extDiscountIi.id, false)
                              );
                            }

                            const uptInvoice = {
                              ...prevInvoice,
                              company,
                              currency: company.currency,
                              invoice_to: company.invoice_to,
                              markup_percentage: company.markup_percentage,
                              invoice_items: iis,
                            };

                            handleMarkupDiscount(uptInvoice);

                            return uptInvoice;
                          });
                        }
                      }}
                      value={invoice.company.id}
                    >
                      <MenuItem value={0}>
                        <FormattedMessage
                          id="general.Select-client"
                          defaultMessage="Select client"
                        />
                      </MenuItem>
                      {companies.map((company) => {
                        return (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {companyErr && (
                      <FormHelperText sx={{ color: "#bf3333" }}>
                        <FormattedMessage
                          id="general.Client-is-required"
                          defaultMessage="Client is required"
                        />
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="currency-label">
                      <FormattedMessage
                        id="general.Currency"
                        defaultMessage="Currency"
                      />
                    </InputLabel>
                    <Select
                      labelId="currency-label"
                      label={
                        <FormattedMessage
                          id="general.Currency"
                          defaultMessage="Currency"
                        />
                      }
                      name="currency"
                      onChange={(event) => handleOnChangeFormField(event)}
                      value={invoice.currency.toString()}
                    >
                      {Object.keys(Currency)
                        .filter((q) => isNaN(Number(q)))
                        .map((currency, i) => {
                          return (
                            <MenuItem key={currency + i} value={currency}>
                              {currency}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                {invoice.company?.requires_po && (
                  <Grid item sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="po-label">
                        <FormattedMessage
                          id="general.Purchase-Order"
                          defaultMessage="Purchase Order"
                        />
                      </InputLabel>
                      <Select
                        labelId="po-label"
                        label={
                          <FormattedMessage
                            id="general.Purchase-Order"
                            defaultMessage="Purchase Order"
                          />
                        }
                        name="purchase_order"
                        error={poReqErr}
                        onChange={(event) => {
                          const purchase_order =
                            purchaseOrdersMap[event.target.value];

                          if (purchase_order) {
                            setPoReqErr(false);
                            setInvoice({
                              ...invoice,
                              purchase_order,
                            });
                          }
                        }}
                        value={invoice.purchase_order?.id || ""}
                      >
                        <MenuItem value={0}>
                          <FormattedMessage
                            id="general.Select-purchase-order"
                            defaultMessage="Select purchase order"
                          />
                        </MenuItem>
                        {invoice.company?.requires_po &&
                        invoice.purchase_order?.id &&
                        !purchaseOrdersMap[invoice.purchase_order.id] ? (
                          <MenuItem
                            key={invoice.purchase_order.id}
                            value={invoice.purchase_order.id}
                          >
                            <FormattedMessage
                              id="invoice-modal.po-details-fully-invoiced"
                              defaultMessage='#{po_number} "{po_summary}" - amount: {po_amount} - fully invoiced'
                              values={{
                                po_number: invoice.purchase_order.number,
                                po_summary: truncate(
                                  invoice.purchase_order.summary,
                                  30
                                ),
                                po_amount: invoice.purchase_order.amount,
                              }}
                            />
                          </MenuItem>
                        ) : (
                          purchaseOrdersNotFullyInvoiced.map((po: any) => {
                            return (
                              <MenuItem key={po.id} value={po.id}>
                                <FormattedMessage
                                  id="invoice-modal.po-details"
                                  defaultMessage='#{po_number} "{po_summary}" - amount: {po_amount} - invoiced: {po_total_invoiced}'
                                  values={{
                                    po_number: po.number,
                                    po_summary: truncate(po.summary, 30),
                                    po_amount: po.amount,
                                    po_total_invoiced: po.total_invoiced || 0,
                                  }}
                                />
                              </MenuItem>
                            );
                          })
                        )}
                      </Select>
                      {poReqErr && (
                        <FormHelperText sx={{ color: "#bf3333" }}>
                          <FormattedMessage
                            id="invoice-modal.po-is-required"
                            defaultMessage="Purchase order is required"
                          />
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="date"
                      label={
                        <FormattedMessage
                          id="general.Date"
                          defaultMessage="Date"
                        />
                      }
                      type="date"
                      fullWidth
                      value={invoice.date.toISOString().slice(0, 10)}
                      onChange={(event) => {
                        if (event.target.value) {
                          setInvoice({
                            ...invoice,
                            date: new Date(event.target.value),
                          });
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="status-label">
                      <FormattedMessage
                        id="general.Status"
                        defaultMessage="Status"
                      />
                    </InputLabel>
                    <Select
                      labelId="status-label"
                      label={
                        <FormattedMessage
                          id="general.Status"
                          defaultMessage="Status"
                        />
                      }
                      name="status"
                      onChange={(event) => handleOnChangeFormField(event)}
                      value={invoice.status.toString()}
                    >
                      {Object.keys(InvoiceStatus)
                        .filter((q) => isNaN(Number(q)))
                        .map((status, i) => {
                          return (
                            <MenuItem key={status + i} value={status}>
                              {renderStatus(status)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={6}>
                  <CompaniesAutocomplete
                    selectedCompanyId={invoice.company.id}
                    value={invoice.invoice_to}
                    handleOnChange={(invoice_to) => {
                      setInvoice({
                        ...invoice,
                        invoice_to,
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  {Boolean(invoice?.invoice_to) && (
                    <TextField
                      fullWidth
                      id="markup_percentage"
                      name="markup_percentage"
                      value={invoice.markup_percentage || "0"}
                      label={intl.formatMessage({
                        id: "general.Markup-Percentage",
                        defaultMessage: "Markup Percentage",
                      })}
                      type="number"
                      variant="outlined"
                      onChange={(event) => {
                        const value = Number(event.target.value);

                        if (value >= 0 && value <= 100) {
                          setInvoice((prevInvoice) => {
                            const uptInvoice = {
                              ...prevInvoice,
                              markup_percentage: value,
                            };

                            handleMarkupDiscount(uptInvoice);

                            return uptInvoice;
                          });
                        }
                      }}
                    />
                  )}
                </Grid>
              </Grid>

              <div style={{ paddingLeft: 5 }}>
                {hasTask && !invoice && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{task.title}</span>
                    <IconButton
                      aria-label="delete"
                      disabled
                      style={{
                        padding: 6,
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Typography variant="h5">
                    <FormattedMessage
                      id="invoice-modal.Invoice-items"
                      defaultMessage="Invoice items"
                    />
                  </Typography>
                  <IconButton
                    size="large"
                    title={intl.formatMessage({
                      id: "invoice-modal.Add-invoice-item",
                      defaultMessage: "Add invoice item",
                    })}
                    onClick={() => {
                      const newIi: InvoiceItem = {
                        id: newIIid - 1,
                        price: 0,
                        title: "New invoice item",
                        order: 0,
                        is_markup_discount: false,
                      };

                      setNewIIid(newIIid - 1);

                      const newIis = [newIi, ...iis].map((ii, index) => {
                        return {
                          ...ii,
                          order: index,
                        };
                      });

                      setIis(newIis);

                      setInvoice({
                        ...invoice,
                        invoice_items: newIis,
                      });
                    }}
                  >
                    <AddCircle />
                  </IconButton>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          <div className="invoice-items-container">
                            {Boolean(isEditingInvoice || iis.length) &&
                              iis?.map((ii, index) => {
                                return (
                                  <Draggable
                                    key={ii.id}
                                    draggableId={String(ii.id)}
                                    index={index}
                                  >
                                    {(provided: any, snapshot: any) => (
                                      <InvoiceItemRow
                                        forwardRef={provided.innerRef}
                                        {...provided.draggableProps}
                                        dragHandleProps={
                                          provided.dragHandleProps
                                        }
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                        isDragging={snapshot.isDragging}
                                        key={ii.id}
                                        ii={ii}
                                        openConfirmItemModal={
                                          openConfirmItemModal
                                        }
                                        openTaskModal={(task) => {
                                          if (
                                            task?.seen_by &&
                                            !task.seen_by.length
                                          ) {
                                            setIis(
                                              [...iis].map((i) => {
                                                if (i.id === ii.id)
                                                  i.task.seen_by = [
                                                    { id: user.id },
                                                  ];

                                                return i;
                                              })
                                            );
                                          }

                                          handleOpenTaskModal(task);
                                        }}
                                        updateItem={updateIi}
                                        deleteDisabled={false}
                                      />
                                    )}
                                  </Draggable>
                                );
                              })}
                            {provided.placeholder}
                          </div>
                        </div>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </div>
              <Grid container>
                <Grid item sm={12} textAlign="right" mt={2}>
                  <span>{invoice.currency} </span>
                  <span>{getTotalSum()} </span>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEditingInvoice && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    <FormattedMessage
                      id="general.Delete-Invoice"
                      defaultMessage="Delete Invoice"
                    />
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  <FormattedMessage
                    id="general.Cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  {isEditingInvoice ? (
                    <FormattedMessage
                      id="general.Update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="general.Create"
                      defaultMessage="Create"
                    />
                  )}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditingInvoice) {
            handleDelete(invoice);
          }
        }}
        text={intl.formatMessage({
          id: "quote-modal.Do-you-really-want-to-delete-quote",
          defaultMessage: "Do you really want to delete the quote?",
        })}
      />
      <ConfirmDialog
        openModal={openConfirmModalItem}
        setOpenModal={setOpenConfirmModalItem}
        confirmHandler={() => {
          handleDeleteInvoiceItem(iiToDelete);
        }}
        text={intl.formatMessage({
          id: "quote-modal.Do-you-really-want-to-delete-quote-item",
          defaultMessage: "Do you really want to delete the quote item?",
        })}
      />
      <CreateTask
        openModal={openTaskModal}
        setOpenModal={setOpenTaskModal}
        task={modalTask}
        projects={projectOptions}
        updateCallback={handleUpdateTaskCallback}
        deleteCallback={handleDeleteTaskCallback}
      />
    </div>
  );
};

export default InvoiceModal;
