import * as enLocale from "./en.json";
import * as frLocale from "./fr.json";
import * as bgLocale from "./bg.json";

const languages = {
  en: enLocale,
  fr: frLocale,
  bg: bgLocale,
};

export const DEFAULT_LANGUAGE = "en";

export default languages;
