import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { Quote } from "../components/task/types";
import { fetchSingleQuoteWithouState } from "../redux/reducerSlices/quote";
import { showHourlyRate } from "../components/task/functions";

const PrintQuote = () => {
  const params = useParams();
  const intl = useIntl();

  const [quote, setQuote] = useState<Quote | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const singleQuote = await fetchSingleQuoteWithouState(params.id);

      setQuote(singleQuote);
      document.title = intl.formatMessage(
        {
          id: "general.Fornetex-Quote-{id}",
          defaultMessage: "Fornetex Quote {id}",
        },
        {
          id: singleQuote.number,
        }
      );
    };

    fetch();
  }, [params.id, intl]);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="h4" component="h1" fontWeight={900}>
          <FormattedMessage
            id="general.Fornetex-Quote-{id}"
            defaultMessage="Fornetex Quote {id}"
            values={{
              id: quote?.number,
            }}
          />
        </Typography>
        <Typography variant="h5" component="h2" fontWeight={900}>
          <FormattedMessage id="general.Client" defaultMessage="Client" />
        </Typography>
        <Typography variant="body1" component="p">
          {quote?.company.name}
        </Typography>
        <Typography variant="body1" component="p">
          {quote?.company.addressLine1}
        </Typography>
        <Typography variant="body1" component="p">
          {quote?.company.addressLine2}
        </Typography>
        <Typography variant="body1" component="p">
          {quote?.company.city} {quote?.company.zipCode}
        </Typography>
        <Typography variant="body1" component="p">
          {quote?.company.country}
        </Typography>
        <Typography variant="h5" component="h2" fontWeight={900}>
          <FormattedMessage
            id="general.Functionalities"
            defaultMessage="Functionalities"
          />
        </Typography>
        {quote?.quote_items.map((qi) => (
          <Box>
            <Typography variant="h6" component="h3">
              {qi.title}
            </Typography>
            <Typography variant="body1" component="pre">
              {qi.description}
            </Typography>
          </Box>
        ))}
        <Typography variant="h5" component="h2" fontWeight={900}>
          <FormattedMessage id="general.Price" defaultMessage="Price" />
        </Typography>
        {quote?.quote_items.map((qi) => (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" component="p">
              {qi.title}{" "}
              {showHourlyRate(
                qi.task?.invoicing_scheme,
                quote.company?.hourly_rate,
                qi.price
              )}
            </Typography>

            <Typography variant="body1" component="p">
              {Number(qi.price).toLocaleString()} {quote?.currency}
            </Typography>
          </Box>
        ))}
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" component="h2" fontWeight={900}>
            <FormattedMessage id="general.Total" defaultMessage="Total" />
          </Typography>
          <Typography variant="h5" component="h2" fontWeight={900}>
            {quote?.quote_items.reduce((acc, qi) => acc + qi.price, 0)}{" "}
            {quote?.currency}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default PrintQuote;
